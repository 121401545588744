import * as UserAction from '../actions/UserAction';
import { ActionType, getType } from 'typesafe-actions';

const defaultState = {
  company: null,
  full_name: null,
  id: null,
  phone_number: null,
  settings: null,
  username: null,
  token: null,
  role_id: '',
};
type UserActionType = ActionType<typeof UserAction>;

export default function user(
  state: CurrentUser = defaultState,
  action: UserActionType
): CurrentUser {
  switch (action.type) {
    case getType(UserAction.setCurrentUser):
      return { ...state, ...action.payload };
    case getType(UserAction.clearCurrentUser):
      return defaultState;
    default:
      return state;
  }
}
