import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import Loadable from './components/Loadable';
import { URL_HOME } from './constants/URLS';
import { notLoginRedirect } from './utils/route';

const Admin = Loadable(React.lazy(() => import('./containers/Admin')));

const AuthenticatePage = Loadable(
  React.lazy(() => import('./containers/Authentication/AuthenticatePage'))
);

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>SourceSage</title>
        </Helmet>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={URL_HOME} />} />
          <Route
            exact
            path="/login"
            component={notLoginRedirect(AuthenticatePage)}
          />
          <Route exact path="/signup" component={AuthenticatePage} />
          <Route exact path="/forget-verify" component={AuthenticatePage} />
          <Route path={URL_HOME} component={Admin} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
