export const URL_HOME = '/home';

// Analytics Overview
export const URL_ANALYTICS = '/home/analytics';
export const URL_ANALYTICS_OVERVIEW = '/home/analytics/overview';
export const URL_ANALYTICS_WEEKLY_OVERVIEW = '/home/analytics/weekly-overview';
export const URL_ANALYTICS_WEEKLY_OVERVIEW_PRODUCT =
  '/home/analytics/weekly-overview/product';
export const URL_ANALYTICS_WEEKLY_OVERVIEW_REPORT =
  '/home/analytics/weekly-overview/report';

// Analytics User Usage
export const URL_ANALYTICS_USER = '/home/analytics/users';
export const URL_ANALYTICS_USER_DETAIL = '/home/analytics/users/:id/';
export const URL_ANALYTICS_USER_INFO = '/home/analytics/users/:id/info';
export const URL_ANALYTICS_USER_VISIT = '/home/analytics/users/:id/visits';
export const URL_ANALYTICS_USER_SEARCHED_KEYWORDS =
  '/home/analytics/users/:id/searched-keywords';
export const URL_ANALYTICS_USER_PRODUCTS = '/home/analytics/users/:id/products';
export const URL_ANALYTICS_USER_REPORTS = '/home/analytics/users/:id/reports';
export const URL_ANALYTICS_USER_PRICE_ALERTS =
  '/home/analytics/users/:id/price-alerts';

// Analytics Product Usage
export const URL_ANALYTICS_PRODUCT = '/home/analytics/products';
export const URL_ANALYTICS_PRODUCT_DETAIL = '/home/analytics/products/:id/';
export const URL_ANALYTICS_PRODUCT_INFO = '/home/analytics/products/:id/info';
export const URL_ANALYTICS_PRODUCT_FREE_TRIAL =
  '/home/analytics/products/:id/free-trials';
export const URL_ANALYTICS_PRODUCT_SUBCRIBER =
  '/home/analytics/products/:id/subscribers';
export const URL_ANALYTICS_PRODUCT_PRICE_ALERT =
  '/home/analytics/products/:id/price-alerts';
export const URL_ANALYTICS_PRODUCT_FAVOURITES =
  '/home/analytics/products/:id/favourites';
export const URL_ANALYTICS_PRODUCT_DOWNLOAD =
  '/home/analytics/products/:id/downloads';

// Analytics Report Usage
export const URL_ANALYTICS_REPORT = '/home/analytics/reports';
export const URL_ANALYTICS_REPORT_DETAIL = '/home/analytics/reports/:id/';
export const URL_ANALYTICS_REPORT_INFO = '/home/analytics/reports/:id/info';
export const URL_ANALYTICS_REPORT_FREE_TRIAL =
  '/home/analytics/reports/:id/free-trials';
export const URL_ANALYTICS_REPORT_SUBCRIBER =
  '/home/analytics/reports/:id/subscribers';
export const URL_ANALYTICS_REPORT_FAVOURITES =
  '/home/analytics/reports/:id/favourites';
export const URL_ANALYTICS_REPORT_DOWNLOAD =
  '/home/analytics/reports/:id/downloads';

// KPI
export const URL_KPI = '/home/kpi';
export const URL_KPI_CUSTOMER_SUCCESS = '/home/kpi/customer_success';
export const URL_KPI_DAILY_UPDATE = '/home/kpi/daily_update';
export const URL_KPI_DAILY_UPDATE_CREATE = '/home/kpi/daily_update/create';
export const URL_KPI_DAILY_UPDATE_ALL = '/home/kpi/daily_update/all';
export const URL_KPI_DAILY_UPDATE_CONTENTS = '/home/kpi/daily_update/contents';
export const URL_KPI_DAILY_UPDATE_CUSTOMER_SUCCESS =
  '/home/kpi/daily_update/customer_success';
export const URL_KPI_DAILY_UPDATE_ENGINEERING_1 =
  '/home/kpi/daily_update/engineering_1';
export const URL_KPI_DAILY_UPDATE_ENGINEERING_2 =
  '/home/kpi/daily_update/engineering_2';
export const URL_KPI_DAILY_UPDATE_PRODUCT = '/home/kpi/daily_update/product';

// Back Office
export const URL_BACK_OFFFICE = '/home/back-office';
export const URL_BACK_OFFFICE_PRICE_ALERT = '/home/back-office/price-alerts';
export const URL_BACK_OFFFICE_TRIGGER = '/home/back-office/triggers';
export const URL_BACK_OFFFICE_EMAIL_MONITOR = '/home/back-office/email-monitor';
export const URL_BACK_OFFFICE_OOR = '/home/back-office/oor';
export const URL_BACK_OFFFICE_FREE_TRIAL = '/home/back-office/free-trials';
export const URL_BACK_OFFFICE_SUB_REQUEST =
  '/home/back-office/subscription-requests';
export const URL_BACK_OFFFICE_SUB_REQUEST_DETAIL =
  '/home/back-office/subscription-requests/:id/';
