import * as Raven from 'raven-js';
import createFilter from 'redux-persist-transform-filter';
import createRavenMiddleware from 'raven-for-redux';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { Persistor, persistReducer, persistStore } from 'redux-persist';

const enhancers = [];
const raven = createRavenMiddleware(Raven);
const initialState = {
  user: {
    company: null,
    full_name: null,
    id: null,
    phone_number: null,
    settings: null,
    username: null,
    token: null,
    role_id: '',
  },
};
const middleware = [raven, thunk];
if (process.env.REACT_APP_SENTRY_DSN) {
  Raven.config(process.env.REACT_APP_SENTRY_DSN).install();
}

const saveSubsetFilter = createFilter('user', [
  'id',
  'company.id',
  'is_staff',
  'is_admin',
]);
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  transforms: [saveSubsetFilter],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);
const configureStore = (): {
  store: Store<RootState>;
  persistor: Persistor;
} => {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
