import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const locationHelper = locationHelperBuilder({});
export const getPathWithLevel = (path: string, level: number) =>
  path
    .split('/')
    .slice(0, level)
    .join('/');

export const loginRequired = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state: RootState) => state.user.id != null,
  // A nice display name for this check
  wrapperDisplayName: 'LoginRequired',
});

export const staffRequired = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state: RootState) =>
    !!(state.user.id != null && (state.user.is_admin || state.user.is_staff)),
  wrapperDisplayName: 'StaffRequired',
});

export const adminRequired = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state: RootState) =>
    !!(state.user.id != null && state.user.is_admin),
  wrapperDisplayName: 'StaffRequired',
});

export const notLoginRedirect = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps: any) => {
    let redirectUrl = locationHelper.getRedirectQueryParam(ownProps);
    return redirectUrl || '/';
  },
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state: RootState) => state.user.id === null,
  // A nice display name for this check
  wrapperDisplayName: 'NotLoginRedirect',
});
