import { LocaleProvider } from 'antd';
import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { getAppLocale, getFormats, getLocaleMessage } from '../utils/intl';

// TODO: store locale in redux or context so that user can change it
const locale = window.location.search.replace('?locale=', '') || 'en';
const appLocale = getAppLocale(locale);
const { localMsg, antdMsg } = getLocaleMessage(appLocale);

export default (props: any) => {
  return (
    <LocaleProvider locale={antdMsg}>
      <IntlProvider
        locale={appLocale}
        key={appLocale}
        messages={localMsg}
        formats={getFormats()}
      >
        {props.children}
      </IntlProvider>
    </LocaleProvider>
  );
};
