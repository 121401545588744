import antdEn from 'antd/lib/locale-provider/en_US';
import antdZh from 'antd/lib/locale-provider/ja_JP';
import antdJa from 'antd/lib/locale-provider/zh_TW';
import { includes } from 'lodash';

const DEFAULT_LOCALE = 'en';
const SUPPORTED_LOCALES = [DEFAULT_LOCALE, 'zh', 'ja'];

/**
 * Define custom formatters here.
 */
export const getFormats = () => ({
  number: {
    usd: { style: 'currency', currency: 'USD' },
  },
});

/**
 * Given browser's locale, returns only locales supported by the app. Defaults to 'en'.
 */
export function getAppLocale(browserLocale: string): string {
  if (browserLocale && includes(SUPPORTED_LOCALES, browserLocale.slice(0, 2))) {
    return browserLocale;
  }
  return DEFAULT_LOCALE;
}

export function getLocaleMessage(browserLocale: string) {
  // Split locales with a region code
  const languageWithoutRegionCode = browserLocale
    .toLowerCase()
    .split(/[_-]+/)[0];
  const localMsg = flattenMessages(
    getLocalTranslation(languageWithoutRegionCode)
  );
  const antdMsg = getAntdTranslation(languageWithoutRegionCode);
  return { browserLocale, localMsg, antdMsg };
}
function getAntdTranslation(locale: string) {
  if (locale.startsWith('zh')) {
    const providerZh: typeof antdZh = require('antd/lib/locale-provider/zh_TW');
    return providerZh;
  } else if (locale.startsWith('ja') || locale.startsWith('jp')) {
    const providerJa: typeof antdJa = require('antd/lib/locale-provider/ja_JP');
    return providerJa;
  }
  const providerEn: typeof antdEn = require('antd/lib/locale-provider/en_US');
  return providerEn;
}

function getLocalTranslation(locale: string) {
  if (locale.startsWith('zh')) {
    const providerZh = require('../constants/translations/frontend/zh-CN');
    return providerZh;
  } else if (locale.startsWith('ja')) {
    const providerJa = require('../constants/translations/frontend/ja');
    return providerJa;
  }
  const providerEn = require('../constants/translations/frontend/en-US');
  console.log('DES', providerEn);
  return providerEn;
}

function flattenMessages(nestedMessages: any, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: any, key: string) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}
