import React from 'react';

import TopBarProgress from './TopProgress';

const Loadable = (Component: any) => (props: any) => (
  <React.Suspense fallback={<TopBarProgress />}>
    <Component {...props} />
  </React.Suspense>
);
export default Loadable;
