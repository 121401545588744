// Do not define color as name like green, red, blue...
// Let's define color as purpose of usage like textMedium, bgMedium...
export const primaryColor = '#8158FC';
export const primaryDarkerColor = '#49356C';
export const primaryLighterColor = '#f2eeff';
export const contrastColor = '#f9f3c9';
export const white = '#fff';

export const successColor = '#00BD56';
export const warningColor = '#ffce00';
export const errorColor = '#f04141';
export const infoColor = '#0496FF';
